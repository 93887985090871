// material-ui
import { withStyles } from "@material-ui/styles";
import Centrifuge from "centrifuge";
// constants
import { CENTRIFUGO_TOKEN, CENTRIFUGO_URL } from "config";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import SockJS from "sockjs-client";
// styles
import styles from "./styles";

class Centrifugo extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
  };

  static childContextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.centrifuge = new Centrifuge(CENTRIFUGO_URL, {
      sockjs: SockJS,
    });
    this.connect();
  }

  getChildContext() {
    return {
      WebsocketCenter: {
        subscribe: this.subscribe.bind(this),
        connect: this.connect.bind(this),
        getInstance: this.getInstance.bind(this),
      },
    };
  }

  getInstance() {
    return this.centrifuge;
  }

  connect() {
    this.centrifuge.setToken(CENTRIFUGO_TOKEN);
    this.centrifuge.connect();
  }

  subscribe(channel, onMessageReceived) {
    const subscription = this.centrifuge.subscribe(channel, (m) => {
      if (onMessageReceived) onMessageReceived(m);
    });
    return subscription;
  }

  render() {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default withStyles(styles)(Centrifugo);
