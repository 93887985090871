import { networkAction } from 'helpers/network/networkAction';

import {
  GET_QUOTES,
  GET_QUOTE_BY_ID,
  DELETE_QUOTE,
  UPDATE_QUOTE,
  CREATE_QUOTE,
} from 'constants/quotes';

import * as Api from 'api';

export const getQuotes = () => async dispatch => networkAction(
  dispatch,
  GET_QUOTES,
  Api.getQuotes,
  []
);

export const getQuoteById = (id) => async dispatch => networkAction(
  dispatch,
  GET_QUOTE_BY_ID,
  Api.getQuoteById,
  [id]
);

export const deleteQuote = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_QUOTE,
  Api.deleteQuote,
  [id]
);

export const createQuote = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_QUOTE,
  Api.createQuote,
  [body]
);

export const updateQuote = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_QUOTE,
  Api.updateQuote,
  [id, body]
);
