export default (theme) => {
  const darkMode = theme.palette.type === "dark";
  return {
    root: {
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: 4,
      width: "100%",
      maxWidth: 600,
      justifyContent: "center",
    },
  };
};
