// material-ui
import { withStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
// components
import App from "components/App";
import Centrifugo from "components/Centrifugo";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createQuote: (...args) => dispatch(Actions.createQuote(...args)),
});

class Root extends Component {
  static propTypes = {
    getStudioSessionMercury: PropTypes.func,
  };

  render() {
    const { getStudioSessionMercury, createQuote } = this.props;

    const api = {
      getStudioSessionMercury,
    };
    return (
      <Centrifugo>
        <App createQuote={createQuote} />
      </Centrifugo>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
