import * as d3 from "d3";
import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";

const random = [
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M576 224v192a32 32 0 0 1-32 32H32a32 32 0 0 1-32-32V224h48v176h480V224z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M576 96v128H464v80a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-80h-80v80a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-80h-80v80a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-80H0V96a32 32 0 0 1 32-32h512a32 32 0 0 1 32 32z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M256 352a24 24 0 0 0-24 24v103.47c7.91.32 15.9.53 24 .53s16.09-.21 24-.53V376a24 24 0 0 0-24-24zM96 320a24 24 0 0 0-24 24v101.76a327.89 327.89 0 0 0 48 17V344a24 24 0 0 0-24-24zm320 0a24 24 0 0 0-24 24v118.77a327.89 327.89 0 0 0 48-17V344a24 24 0 0 0-24-24zm93.31-267.56l-8.87-13.31a16 16 0 0 0-22.19-4.44L232.88 192.94a16 16 0 0 0-4.44 22.19l8.87 13.31a16 16 0 0 0 22.19 4.44L504.88 74.63a16 16 0 0 0 4.43-22.19z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M512 208v160c0 30.23-27.5 57.61-72 77.76V344a24 24 0 0 0-48 0v118.77c-33.05 9.1-71.07 15-112 16.7V376a24 24 0 0 0-48 0v103.47c-40.93-1.67-78.95-7.6-112-16.7V344a24 24 0 0 0-48 0v101.76C27.5 425.61 0 398.23 0 368V208a37.5 37.5 0 0 1 1.33-10.38C15.09 101.32 214.73 96 256 96c17.58 0 63.9 1 112.32 9.58l-62.55 40.34c-16-1.21-32.58-1.92-49.77-1.92-114.87 0-208 28.65-208 64s93.13 64 208 64 208-28.65 208-64c0-21.27-33.87-40.07-85.77-51.7l53.23-34.3c40.86 15 73.91 38.5 79.21 75.65A37.5 37.5 0 0 1 512 208z"
    ></path>
  </>,
  <>
    <g class="fa-group">
      <path
        class="fa-secondary"
        fill="currentColor"
        d="M630.13 129.22a16 16 0 0 0-17.44 3.47L569.38 176H101.2a32 32 0 0 0-10.13 1.64l-80.13 26.71A16 16 0 0 0 0 219.53v72.94a16 16 0 0 0 10.94 15.18l80.13 26.71A32 32 0 0 0 101.2 336h468.18l43.31 43.31A16 16 0 0 0 640 368V144a16 16 0 0 0-9.87-14.78zM272 280a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm96 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm96 0a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"
        opacity="0.4"
      ></path>
      <path
        class="fa-primary"
        fill="currentColor"
        d="M368 232a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm-96 0a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm232-120H232a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v32h32v-32h64v32h32v-32h64v32h32v-32h24a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm-40 120a24 24 0 1 0 24 24 24 24 0 0 0-24-24z"
      ></path>
    </g>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M243.76 223.27a39.45 39.45 0 0 1-3.18-38.42l17.29-37.13a18.82 18.82 0 0 0-30.38-21.23l-38.31 38.27a77.49 77.49 0 0 0-18.24 28.83A77.6 77.6 0 0 1 130.42 238L54 273.33C-3.23 296.19-18.4 370 25.16 413.54l73.39 73.33c43.56 43.51 117.45 28.36 140.35-28.78l35.4-76.38a77.64 77.64 0 0 1 44.41-40.48A77.77 77.77 0 0 0 347.57 323l21.57-21.55a19.87 19.87 0 0 0-20.11-33L325.32 276a39.67 39.67 0 0 1-36.76-7.11zm-62.1 171.09l-11.31 11.31a8 8 0 0 1-11.32 0L106.34 353a8 8 0 0 1 0-11.31l11.32-11.31a8 8 0 0 1 11.31 0l52.69 52.68a8 8 0 0 1 0 11.3zm64-64l-11.31 11.31a8 8 0 0 1-11.32 0L170.35 289a8 8 0 0 1 0-11.31l11.31-11.31a8 8 0 0 1 11.31 0l52.69 52.68a8 8 0 0 1 0 11.31z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M511.21 39.57a48.12 48.12 0 0 0-37.79-38.26C450.48-3 450.66.79 366.74 54.93A32.15 32.15 0 0 0 352 81.88v33.27L243.81 223.33l44.75 45.6.09.05 173-172.93c.82.06 1.57.35 2.4.35a48 48 0 0 0 47.16-56.83zM129 330.37a8 8 0 0 0-11.31 0l-11.32 11.31a8 8 0 0 0 0 11.31L159 405.67a8 8 0 0 0 11.32 0l11.31-11.31a8 8 0 0 0 0-11.31zm64-64a8 8 0 0 0-11.31 0l-11.31 11.31a8 8 0 0 0 0 11.31L223 341.68a8 8 0 0 0 11.32 0l11.31-11.31a8 8 0 0 0 0-11.31z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M608 176H96v160h512a32 32 0 0 0 32-32v-96a32 32 0 0 0-32-32z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M320 232a24 24 0 1 0 24 24 24 24 0 0 0-24-24zM0 208v96a32 32 0 0 0 32 32h64V176H32a32 32 0 0 0-32 32zm416 24a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm96 0a24 24 0 1 0 24 24 24 24 0 0 0-24-24z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M172 90l25.6 44.34a73.16 73.16 0 0 1-20.94 96.53C100.23 217.46 48 190.78 48 160c0-30.16 50.11-56.39 124-70zm116 70a64.07 64.07 0 0 0 64-64V83c-20.4-1.88-41.8-3-64-3s-43.6 1.08-64 3v13a64.07 64.07 0 0 0 64 64zm110.93 70.9C475.6 217.54 528 190.83 528 160c0-30.21-50.28-56.5-124.44-70.1l-25.65 44.42a73.13 73.13 0 0 0 21 96.58zm-50 6.4c-8.1-26.13-32.19-45.3-60.93-45.3s-52.83 19.17-60.89 45.3c19.48 1.7 39.81 2.7 60.89 2.7s41.41-1 60.89-2.7z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M288 32C128.94 32 0 89.31 0 160v192c0 70.69 128.94 128 288 128s288-57.31 288-128V160c0-70.69-128.94-128-288-128zm-83 158.36a73 73 0 0 1-28.31 40.48C100.23 217.46 48 190.78 48 160c0-30.16 50.11-56.39 124-70l25.6 44.34a73.39 73.39 0 0 1 7.4 56.02zM288 240c-21.08 0-41.41-1-60.89-2.7 8.06-26.13 32.15-45.3 60.89-45.3s52.83 19.17 60.89 45.3C329.41 239 309.08 240 288 240zm64-144a64 64 0 0 1-128 0V83c20.4-1.88 41.8-3 64-3s43.6 1.08 64 3zm46.93 134.9a73.13 73.13 0 0 1-21-96.58l25.63-44.42C477.72 103.5 528 129.79 528 160c0 30.83-52.4 57.54-129.07 70.9z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M512,384v96a32,32,0,0,1-32,32H32A32,32,0,0,1,0,480V384H48v80H464V384Z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M512,327.55V384H432v40a8,8,0,0,1-8,8H408a8,8,0,0,1-8-8V384H368v40a8,8,0,0,1-8,8H344a8,8,0,0,1-8-8V384H240v40a8,8,0,0,1-8,8H216a8,8,0,0,1-8-8V384H176v40a8,8,0,0,1-8,8H152a8,8,0,0,1-8-8V384H112v40a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V384H0V184.45A184.45,184.45,0,0,1,184.45,0h15.1A184.45,184.45,0,0,1,384,184.45h0a64,64,0,0,0,35.38,57.24l57.24,28.62A64,64,0,0,1,512,327.55Z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M274.64 267.42a56.8 56.8 0 1 0 33.92 33.94l198.69-198.74a16 16 0 0 0 0-22.62l-11.31-11.31a16 16 0 0 0-22.62 0z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M500 462.92A96.17 96.17 0 0 1 415.87 512H96.13A96.17 96.17 0 0 1 12 462.92a92.48 92.48 0 0 1 2.16-94.83l159.86-258.7c11.72-19 29.55-32.22 50-39.34V16A16 16 0 0 1 240 0h32a16 16 0 0 1 16 16v54.05c20.41 7.12 38.24 20.37 50 39.34l36.16 58.53-46.48 46.5-44.11-71.37c-8.4-13.58-22-15.05-27.52-15.05s-19.12 1.47-27.52 15.05L68.61 401.75a29 29 0 0 0-.69 30A31.73 31.73 0 0 0 96.13 448h319.74a31.73 31.73 0 0 0 28.21-16.23 29 29 0 0 0-.69-30l-89.84-145.41L400 209.87l97.77 158.22a92.48 92.48 0 0 1 2.23 94.83z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M457.77 227.48l-39.83 39.83a16 16 0 0 1-22.63 0l-22.62-22.62a16 16 0 0 1 0-22.63l39.83-39.83c-12.17-8.77-25.53-15.76-40.15-19.16-32.87-7.65-65.69-.93-88.56 21.05A79.09 79.09 0 0 1 288 208.4a79.78 79.78 0 0 1-123.28 67.08 89.85 89.85 0 0 0-7.16 6c-38.07 38-38.17 101.14-5.1 153.3L204 400.39a16 16 0 0 1 20.19 2l13.45 13.45a16 16 0 0 1 2 20.19l-34.35 51.53c52.16 33 115.31 32.85 153.26-5.12a89.77 89.77 0 0 0 6.23-7.48A79.27 79.27 0 0 1 455.87 356c21.92-22.84 28.6-55.6 21-88.4-3.36-14.6-10.34-27.95-19.1-40.12zM472 48a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm96 96a24 24 0 1 0 24 24 24 24 0 0 0-24-24zM520 0a24 24 0 1 0 24 24 24 24 0 0 0-24-24zm96 96a24 24 0 1 0 24 24 24 24 0 0 0-24-24z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M224.16 402.39a16 16 0 0 0-20.19-2l-68.84 45.9a16 16 0 0 0-2.44 24.62l36.4 36.4a16 16 0 0 0 24.62-2.44l45.9-68.87a16 16 0 0 0-2-20.19zM336 4.69a16 16 0 0 0-22.62 0L4.69 313.38a16 16 0 0 0 0 22.62L16 347.31a16 16 0 0 0 22.62 0L347.31 38.62a16 16 0 0 0 0-22.62zm299.31 22.62L612.69 4.69a16 16 0 0 0-22.63 0L372.69 222.06a16 16 0 0 0 0 22.63l22.62 22.62a16 16 0 0 0 22.63 0L635.31 49.94a16 16 0 0 0 0-22.63z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M192 200A120 120 0 1 1 72 320a120.13 120.13 0 0 1 120-120m0-48a168 168 0 1 0 168 168 168 168 0 0 0-168-168z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M194.34 381.66l11.31-11.31a8 8 0 0 0 0-11.32L153 306.35a8 8 0 0 0-11.32 0l-11.31 11.31a8 8 0 0 0 0 11.31L183 381.66a8 8 0 0 0 11.34 0zM502.63 39L473.05 9.37a32 32 0 0 0-45.26 0l-46.31 46.32A35.26 35.26 0 0 0 373 69.48l-12.19 36.59L286 180.79A168.81 168.81 0 0 1 331.29 226l74.88-74.88L442.52 139a35.12 35.12 0 0 0 13.79-8.52l46.32-46.31a32 32 0 0 0 0-45.17zM56.48 221.48a168.39 168.39 0 0 1 37-37l-.23-.23a26.18 26.18 0 0 0-37 37zm0 197l-.23.22a26.18 26.18 0 1 0 37 37l.23-.23a168 168 0 0 1-37-36.95zm-30.3-124.7a26.18 26.18 0 0 0 0 52.36h.46C25.29 337.59 24 329 24 320s1.29-17.59 2.64-26.18zm301.34 124.7a168 168 0 0 1-37 37l.23.23a26.18 26.18 0 1 0 37-37zm30.3-124.7h-.46C358.71 302.41 360 311 360 320s-1.29 17.59-2.64 26.18h.46a26.18 26.18 0 0 0 0-52.36zm-192 191.54v.46a26.18 26.18 0 0 0 52.36 0v-.46C209.59 486.71 201 488 192 488s-17.59-1.29-26.18-2.64zM192 128a26.18 26.18 0 0 0-26.18 26.18v.46C174.41 153.29 183 152 192 152s17.59 1.29 26.18 2.64v-.46A26.19 26.19 0 0 0 192 128z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M400 160a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v32h48zm-176 0a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v32h48zm88 0a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v32h48zm180.27 109.64c-4.61-2.43-19.86-9.86-44.27-12.45V352a32 32 0 0 1-32 32h-16V256h-48v128h-40V256h-48v128h-40V256H81.75C70.75 274.9 64 296.57 64 320a128 128 0 0 0 128 128h224a96 96 0 0 0 96-96v-70.3c-6.42-4.16-12.78-8.4-19.73-12.06zM176 381.73a63.69 63.69 0 0 1 0-123.46z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M640 80v288c0 12-10.81 16-16 16a16.22 16.22 0 0 1-13-6.67 555.44 555.44 0 0 0-66.19-71.68c-15.38-13-32.93-25.66-52.54-36-5.55-2.93-25.75-13.6-60-13.63H48a16 16 0 0 1-16 16H16A16 16 0 0 1 0 256v-64a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16h384.27c34.25 0 54.45-10.69 60-13.63 19.61-10.35 37.16-23.05 52.54-36A555.44 555.44 0 0 0 611 70.67 16.27 16.27 0 0 1 624 64c5.19 0 16 4 16 16z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M278.63 278.63l-45.25-45.25 41.19-41.19C90.39 190.67 46 241 30.73 256.28c-46.8 46.79-39.51 150.36 17.54 207.45s160.66 64.34 207.45 17.53c15.28-15.26 65.6-59.64 64.08-243.8zM176 384a48 48 0 1 1 48-48 48 48 0 0 1-48 48z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M509.56 70.48l-40.07 64a32 32 0 0 1-19.11 14L394.28 163 278.63 278.63l-45.25-45.25L349 117.74l14.51-56.12a32 32 0 0 1 14-19.11l64-40.07a16 16 0 0 1 19.81 2.25l46 46a16 16 0 0 1 2.24 19.79z"
    ></path>
  </>,
  <>
    <path
      class="fa-secondary"
      fill="currentColor"
      d="M635.28 100.69L608 73.37A32 32 0 0 0 585.34 64h-46.83a64 64 0 0 0-45.26 18.74L210 366a25.41 25.41 0 0 1-39.76-31l47.39-79H240a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v16a15.91 15.91 0 0 0 15.21 15.84C7.48 288.56 0 326.72 0 352c0 61.4 35 114.09 85.73 140.92A150 150 0 0 0 160 512c77.1 0 116.46-51.33 128-64l246.39-308a32 32 0 0 1 25-12H624c14.22 0 21.36-17.23 11.28-27.31zM88 392a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm32-88a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"
      opacity="0.4"
    ></path>
    <path
      class="fa-primary"
      fill="currentColor"
      d="M320 212.69L308.69 224a16 16 0 0 0 0 22.63L319 257l34-34-10.35-10.34a16 16 0 0 0-22.65.03zM177.77 25.6a301.94 301.94 0 0 0-28.1 30A387.31 387.31 0 0 0 100 0C50.88 45.58 16 105 16 140.8a117 117 0 0 0 5.89 35.2h61.34A60.88 60.88 0 0 1 72 140.8c0-7.62 8.66-30.3 28.46-57.54q2 2.61 3.89 5.22L147 147.26l27.06-33.08c6.07 12.1 9.54 22.24 9.92 26.62a60.88 60.88 0 0 1-11.21 35.2h61.34a117 117 0 0 0 5.89-35.2c0-26.63-26-81.57-62.23-115.2zM368 164.69L356.69 176a16 16 0 0 0 0 22.63L367 209l34-34-10.35-10.34a16 16 0 0 0-22.65.03zm70.62-48a16 16 0 0 0-22.62 0L404.69 128a16 16 0 0 0 0 22.63L415 161l34-34z"
    ></path>
    ,
  </>,
  <>
    <g class="fa-group">
      <path
        class="fa-secondary"
        fill="currentColor"
        d="M229.27 300.21A74.51 74.51 0 0 0 237.7 266c0-29.14-17-54.82-43.15-71.17-12.82-8-27.53-12-42.55-13.88v-54.22l16.6-33.21a29 29 0 0 0 3.08-13v-54.1A26.42 26.42 0 0 0 145.25 0h-34.53A26.41 26.41 0 0 0 84.3 26.41v54.08a29.12 29.12 0 0 0 3.07 13L104 126.74V181c-15 1.84-29.74 5.84-42.58 13.83a104 104 0 0 0-18.58 14.67c-15.39 15.39-24.62 35-24.61 56.58a74.36 74.36 0 0 0 8.41 34.2c8 15.65 6 35.59-4.88 48.78C8.11 365.55-.07 385.43 0 407c-.07 58 57.34 105 128 105 39.89 0 75.55-15 99-38.47 1.28-1.27 2.23-2.75 3.43-4.07a111.41 111.41 0 0 1 2.32-80.3L243 361.42a103.64 103.64 0 0 0-8.81-12.42c-10.89-13.16-12.89-33.16-4.92-48.79zm-73 32.08a40 40 0 1 1 0-56.58 40 40 0 0 1 .02 56.58zM424 368h-80a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0-64.12h-80a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8z"
        opacity="0.4"
      ></path>
      <path
        class="fa-primary"
        fill="currentColor"
        d="M505.48 400.82l-26.93-73.45a72.15 72.15 0 0 1 2.57-55.79 72.13 72.13 0 0 0 7-30.94v-28.32a18.47 18.47 0 0 0-34.88-8.47l-10.62 20.55c-5.36 10.37-15.35 17-26.62 19l-8-.1V73.38a34.2 34.2 0 0 0 5.09-3.8 40.77 40.77 0 0 0-51.44-62.75c-16.38 11.15-14 13.35-31.85 96.24a27.3 27.3 0 0 0 7.33 25L360 151v91.66l-8-.11c-11.3-3.11-20.84-11-25-22.37l-13-35.76a17.49 17.49 0 0 0-33.92 6v50.33a72.07 72.07 0 0 0 7 30.93 72.07 72.07 0 0 1 2.53 55.78l-27 73.48C240 453.53 278.54 512 335.76 512h96.41c57.23-.06 95.83-58.59 73.31-111.18zM432 392a8 8 0 0 1-8 8h-80a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h80a8 8 0 0 1 8 8zm0-64.12a8 8 0 0 1-8 8h-80a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h80a8 8 0 0 1 8 8z"
      ></path>
    </g>
  </>,
];

class BubbleChart extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    useLabels: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    useLabels: false,
    width: 600,
    height: 400,
  };

  constructor(props) {
    super(props);

    this.minValue = 1;
    this.maxValue = 100;
    this.mounted = false;

    this.state = {
      data: [],
    };

    this.radiusScale = this.radiusScale.bind(this);
    this.simulatePositions = this.simulatePositions.bind(this);
    this.renderBubbles = this.renderBubbles.bind(this);
  }

  componentWillMount() {
    this.mounted = true;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.length !== this.props.data.length) {
      this.minValue =
        0.95 *
        d3.min(this.props.data, (item) => {
          return item.v;
        });

      this.maxValue =
        1.05 *
        d3.max(this.props.data, (item) => {
          return item.v;
        });

      this.simulatePositions(this.props.data);
    }
  }

  componentDidMount() {
    if (this.props.data.length > 0) {
      this.minValue =
        0.95 *
        d3.min(this.props.data, (item) => {
          return item.v;
        });

      this.maxValue =
        1.05 *
        d3.max(this.props.data, (item) => {
          return item.v;
        });

      this.simulatePositions(this.props.data);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  radiusScale = (value) => {
    const fx = d3
      .scaleSqrt()
      .range([5, 40])
      .domain([this.minValue, this.maxValue]);

    return fx(value);
  };

  simulatePositions = (data) => {
    this.simulation = d3
      .forceSimulation()
      .nodes(data)
      .velocityDecay(0.6)
      .force("x", d3.forceX().strength(0.05))
      .force("y", d3.forceY().strength(0.05))
      .force(
        "collide",
        d3.forceCollide((d) => {
          return this.radiusScale(d.v);
        })
      )
      .on("tick", () => {
        if (this.mounted) {
          this.setState({ data });
        }
      });
  };

  renderBubbles = (data) => {
    const minValue =
      0.95 *
      d3.min(data, (item) => {
        return item.v;
      });

    const maxValue =
      1.05 *
      d3.max(data, (item) => {
        return item.v;
      });

    const color = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .interpolate(d3.interpolateHcl)
      .range(["#eb001b", "#f79e1b"]);

    // render simple circle element
    if (!this.props.useLabels) {
      const circles = _.map(data, (item, index) => {
        return (
          <circle
            key={index}
            r={this.radiusScale(item.v)}
            cx={item.x}
            cy={item.y}
            fill={"#f5f5f5"}
            strokeWidth="2"
          />
        );
      });

      return (
        <g
          transform={`translate(${this.props.width / 2}, ${
            this.props.height / 2
          })`}
        >
          {circles}
        </g>
      );
    }

    // render circle and text elements inside a group
    const texts = _.map(data, (item, index) => {
      const props = this.props;
      const fontSize = this.radiusScale(item.v) / 2;
      const maxScale = 1;
      const minScale = 0.5;
      const b = (minScale - 100 * maxScale) / -99;
      const a = maxScale - b;
      const scale = a * data.length + b;

      return (
        <g
          key={index}
          transform={`scale(${scale}) translate(${
            ((props.width / 2) * 1) / scale + item.x
          }, ${((props.height / 2) * 1) / scale + item.y})`}
        >
          <circle
            r={this.radiusScale(item.v)}
            fill={"#f5f5f5"}
            stroke={"#bdbdbd"}
            strokeWidth="2"
          />
          <g
            transform={`scale(${item.v * 0.0008}) translate(-280,-250)`}
            class="fa-group"
          >
            {random[index % random.length]}
          </g>
        </g>
      );
    });

    return texts;
  };

  render() {
    if (this.state.data.length) {
      return (
        <svg width={this.props.width} height={this.props.height}>
          {this.renderBubbles(this.state.data)}
        </svg>
      );
    }

    return <div>Loading</div>;
  }
}

export default BubbleChart;
