// import PropTypes from 'prop-types';
// material-ui
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  Slider,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import Bubble from "components/Bubble";
import { REACT_APP_INTAKE } from "config";
import PhoneInput from "hooks/PhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import React from "react";
import _ from "underscore";
import * as yup from "yup";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function App({ createQuote }) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [urgent, setUrgent] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const [count, setCount] = React.useState(20);
  const [error, setError] = React.useState();
  const [quote, setQuote] = React.useState();
  const [rawdata, setRawData] = React.useState(
    _.map(_.range(count), () => ({
      v: _.random(10, 100),
    }))
  );
  const theme = useTheme();
  const [workSessionCount, setWorkSessionCount] = React.useState(1);
  const classes = useStyles(styles);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const primaryColor = theme.palette.primary.color[600];

  let schema = yup.object().shape({
    workSessionCount: yup.number().required().positive().integer(),
    musicianCount: yup.number().required().positive().integer(),
    urgent: yup.boolean().required(),
    phone: yup
      .string()
      .test("phone", "${path} format is not recognized", (value) =>
        isValidPhoneNumber(value)
      )
      .required(),
    email: yup.string().email().required(),
    lastName: yup.string().min(1, "Last Name is required").required(),
    firstName: yup.string().min(1, "First Name is required").required(),
  });

  const isValid = () => {
    setError("");
    schema
      .validate({
        firstName,
        lastName,
        workSessionCount,
        musicianCount: count,
        email,
        phone,
        urgent,
      })
      .catch(function (err) {
        console.log(err);
        setError(err.errors);
      })
      .then((body) => {
        if (body) {
          createQuote(body).then((r) => {
            setQuote(r.payload);
          });
        }
      });
  };

  if (quote && quote.reference) {
    return (
      <div className={classes.root} style={{ flex: 1, alignItems: "center" }}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h1">
            <i class="fad fa-file-invoice"></i>
          </Typography>
          <br />
          <Typography variant="h4">
            The Quote has been Generated
            <br />
            <Typography
              variant="h5"
              style={{
                border: "solid 1px black",
                display: "inline-block",
                padding: "2px 8px",
                borderRadius: 8,
              }}
            >
              {quote.reference}
            </Typography>
            <Typography variant="caption" display="block">
              This is your reference number
            </Typography>
          </Typography>
          <br />
          <Typography color="textSecondary">
            Check your email, we sent you the quote. As soon as you are ready,
            provide your project's details to get started
          </Typography>
          <Button
            size="small"
            color="primary"
            variant="contained"
            style={{ marginTop: 20, background: primaryColor }}
            onClick={() =>
              window.location.replace(
                `${REACT_APP_INTAKE}?reference=${quote.reference}`
              )
            }
          >
            Let's do it!
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container style={{ padding: 16, paddingBottom: 150 }}>
        <Grid
          xs={12}
          item
          style={{
            textAlign: "center",
          }}
        >
          <img
            style={{ width: "80%", maxWidth: 240 }}
            alt="rhapsody"
            src="https://storage.googleapis.com/rhapsody/logos/rhapsody-concierge.png"
          />
          <Typography style={{ fontWeight: 600 }}>
            How many musician positions?
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Don't count alternates
          </Typography>
          <br />
          <div
            style={{
              width: 350,
              height: 350,
              margin: "auto",
            }}
          >
            <Bubble
              useLabels
              key={rawdata.length}
              data={rawdata}
              width={350}
              height={350}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", padding: 8, marginTop: 20 }}
        >
          <Slider
            valueLabelDisplay="on"
            value={count}
            min={1}
            onChangeCommitted={(e, v) => {
              setRawData(
                _.map(_.range(v), () => ({
                  v: _.random(10, 100),
                }))
              );
            }}
            style={{ color: primaryColor }}
            onChange={(e, v) => setCount(v)}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", padding: 8 }}>
          <Typography style={{ fontWeight: 600 }}>
            How many work sessions?
          </Typography>
          <Typography variant="caption" color="textSecondary" display="block">
            A work session is your musicians, playing on a specific day and
            location
          </Typography>
          <Slider
            style={{ marginTop: 40, color: primaryColor }}
            valueLabelDisplay="on"
            value={workSessionCount}
            min={1}
            max={20}
            onChange={(e, v) => setWorkSessionCount(v)}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", padding: 8 }}>
          <Typography style={{ fontWeight: 600 }}>Contact Info</Typography>
          <Typography variant="caption" color="textSecondary">
            We'll email you the quote right away
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ padding: 8 }}>
          <TextField
            variant="outlined"
            value={firstName}
            fullWidth
            onChange={(e) => setFirstName(e.target.value)}
            label="First Name"
            size="small"
            inputProps={{
              style: { color: primaryColor },
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: 8 }}>
          <TextField
            variant="outlined"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            label="Last Name"
            size="small"
            inputProps={{
              style: { color: primaryColor },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            variant="outlined"
            fullWidth
            inputProps={{
              style: { color: primaryColor },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon fontSize="inherit" className="fad fa-envelope" />
                </InputAdornment>
              ),
            }}
            label="Email"
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 8 }}>
          <PhoneInput
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            variant="outlined"
            fullWidth
            inputProps={{
              style: { color: primaryColor },
            }}
            helperText="Phone will be verified for authentication"
            label="Phone"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={urgent}
                style={{ color: urgent ? "red" : undefined }}
                onChange={(e) => setUrgent(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ lineHeight: 1 }}>
                  <i
                    class={
                      urgent
                        ? "fad fa-siren-on p-right"
                        : "fad fa-siren p-right"
                    }
                    style={{ color: urgent ? "#f44336" : undefined }}
                  ></i>
                  This is an urgent call
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Sent in as little as 1 hour<sup>1</sup>
                  </Typography>
                </div>
              </div>
            }
          />
        </Grid>
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        ) : (
          []
        )}
        <Grid item xs={12} style={{ padding: 8 }}>
          <Button
            onClick={isValid}
            fullWidth
            variant="contained"
            style={{ background: primaryColor, color: "white" }}
          >
            <i class="fad fa-file-invoice-dollar p-right"></i> Get Instant Quote
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", paddingTop: 20, lineHeight: 0.8 }}
        >
          <div style={{ borderBottom: "solid 1px #e0e0e0", marginBottom: 8 }} />
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ fontSize: 10, lineHeight: 0 }}
          >
            A quote is valid for 10 days. A valid email and phone number are
            required in order to complete the process. The content of the quote
            will be sent by email. You will be asked to take our intake form to
            provide all the required information needed for the concierge to
            send out the call. We reserve the right to refuse service to anyone
            for any reason.
            <br />
            <br />
            1: Your call can be sent in as little as 1 hour after the intake
            form is submitted and verified by your concierge. The demand needs
            to be submitted within business hours: Mon-Fri 10AM - 6PM Pacific.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Typography variant="h6">
            <i class="fad fa-hand-point-right p-right"></i>What to expect next?
          </Typography>
          <Typography variant="body2">
            <ol>
              <li>You receive an instant quote by email.</li>
              <li>
                You decide to move forward by providing more details about your
                project with our online intake form (link included in the
                email).
              </li>
              <li>
                A dedicated concierge contacts you to review your project.
              </li>
              <li>We send the work call.</li>
              <li>
                You have access to the live hiring status 24/7 through our
                portal.
              </li>
              <li>
                We replace declined musicians with their alternates until the
                hiring is completed.
              </li>
              <li>We send you the final rosters for each sessions.</li>
              <li>
                We send a reminder to all relevant musicians prior to each
                session.
              </li>
            </ol>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Typography variant="h6">
            <i class="fad fa-map-marker-question p-right"></i>Frequently Asked
            Questions
          </Typography>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                I don't know exactly how many musicians I need, what should I
                do?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption">
                <b>
                  Provide an estimate. You will be able to adjust your musicians
                  later. The price will be adjusted in consequence.
                </b>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                I am not sure what a work session is, can you explain?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption">
                <b>
                  A work session is your musicians (or some of them) playing on
                  a specific day and location. A single work session can be a
                  double/triple... as long as it is the same day and same
                  location.
                  <br />
                  <br />
                  Examples:
                  <ul>
                    <li>
                      Violins playing a double at Fox on a monday from 8AM-10AM
                      - 1PM/3PM is ONE work session.
                    </li>
                    <li>
                      All musicians playing at the Capitol Records Monday and
                      Tuesday 8AM-11AM is TWO work sessions.
                    </li>
                  </ul>
                  <br />
                  Don't worry if you don't set the correct value when you quote.
                  We can always adjust this value later.
                </b>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                My call is extremely urgent, how could I make sure it is sent in
                time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption">
                <b>
                  The conditions for a call being sent in as little as 1 hour:
                  <ul>
                    <li>The intake form has been properly completed</li>
                    <li>
                      It has been submitted within our business hours: Mon-Fri
                      10AM - 6PM Pacific.
                    </li>
                  </ul>
                  Chat with us if the conditions above are not meet. Additional
                  surcharge may be added.
                </b>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                What is your cancellation policy?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption">
                <b>
                  You can cancel a concierge service if the call has not already
                  been sent.
                  <br />
                  <br />
                  If musicians have been contacted, we will take care to notify
                  them and make sure they acknowledge the cancellation. You will
                  be charged the price of the quote.
                </b>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                What is the hidden fees and Extra costs?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption">
                <b>
                  There are no such fees and extra costs. You will be billed the
                  amount of the quote at the end of the hiring if the number of
                  musicians and work sessions are correct.
                </b>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body2">
                How do I upload my musicians
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption">
                <b>We currently support the VCF format, and Google Contacts.</b>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
}
